export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },
    {
        title: '',
        key: 'MenuName',
        minWidth: 80,
    },

]
